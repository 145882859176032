import VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalMenu/viewer/skinComps/VerticalMenuSeparatedButtonFixedWidthSkin/VerticalMenuSeparatedButtonFixedWidthSkin.skin';
import VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkinController from '@wix/thunderbolt-elements/src/components/VerticalMenu/viewer/VerticalMenu.controller';


const VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkin = {
  component: VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkinComponent,
  controller: VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkinController
};


export const components = {
  ['VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkin']: VerticalMenu_VerticalMenuSeparatedButtonFixedWidthSkin
};

